.flex-container {
  display: flex;
  flex: 1;
}

.flex-column {
  flex: 1;
  height: 100vh;
  border: 0.5px solid #ccc;
  padding: 20px;
  overflow: scroll;
}

.highlight {
  border: 1px solid #ccc;
  padding: 24px;
  margin-top: 24px;
  margin-bottom: 24px;
}

.similar-highlights-button {
  border: none;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 12px;
}

.similar-highlights-button:hover {
  background-color: black;
  color: white;
}